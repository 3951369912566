<template>
  <div>
    <datatable-heading
      :title="$t('menu.application')"
      :changePageSize="changePageSize"
      :searchChange="_.debounce(searchChange, 500)"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :tableFields="fields"
      @filterChange="filterChange"
    >
    </datatable-heading>
    <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
      <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="applicationID"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:row-clicked="rowClicked"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded"
            >
            </vuetable>
          </b-card>
          <vuetable-pagination-bootstrap
            class="mt-4"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />
        </b-colxx>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../components/Common/VuetablePaginationBootstrap'
import { BeaconapiUrl } from '../../../constants/config'
import DatatableHeading from '../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'
import { mapMutations } from 'vuex'
export default {
  props: {
    applicationID: {
      type: Number,
      default: 1
    }
  },
  components: {
    Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading
  },
  data () {
    var _idfield = 'applicationID'
    return {
      isLoad: false,
      apiBase: `${BeaconapiUrl}/api/application/table`,
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      filters: [],
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: '__checkbox',
          title: '',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right selected',
          width: '5%'
        },
        {
          name: _idfield,
          sortField: _idfield,
          title: 'Application ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '9%',
          filterType: 'number'
        },
        {
          name: 'parentCompanyID',
          sortField: 'parentCompanyID',
          title: 'Company ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%',
          filterType: 'text'
        },
        {
          name: 'companyName',
          sortField: 'companyName',
          title: 'Company Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'dateSubmitted',
          sortField: 'dateSubmitted',
          title: 'Date Submitted',
          titleClass: '',
          dataClass: 'text-muted',
          width: '8%',
          filterType: 'text'
        },
        {
          name: 'type',
          sortField: 'type',
          title: 'Type',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'text'
        },
        {
          name: 'status',
          sortField: 'status',
          title: 'Status',
          titleClass: 'center aligned text-center',
          dataClass: 'center aligned text-center',
          width: '10%',
          filterType: 'active'
        }
      ],
      json_data: []
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
      this.json_data = paginationData.data
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    filterChange (filters) {
      this.filters = filters
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {},
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]
      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    rowClicked () {

    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    }
  }
}
</script>
